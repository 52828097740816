/* 下载 */
export function downloadFiles(filePath, fileName) {
    fetch(filePath).then(res => res.blob()).then(blob => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      document.body.appendChild(a); //追加a标签
      a.click(); //a标签点击
      document.body.removeChild(a); //移除节点
    })
  }