<template>
    <el-row :gutter="20">
      <el-card class="box-card">
        <div class="demo-basic--circle">
          <div class="block"><el-avatar :size="120" :src="avatar"></el-avatar></div>
        </div>
          <h1>短视频/图集在线去水印解析</h1>
          <div class="typo">
            <p><strong>目前支持 </strong>抖音/快手/皮皮虾/火山/微视/微博/绿洲/最右/轻视频/instagram/哔哩哔哩/全民小视频/皮皮搞笑/全民k歌/巴塞电影/陌陌/Before避风/开眼/Vue Vlog/小咖秀/西瓜视频</p>
            <p><strong>温馨提示 </strong>粘贴视频地址时<u>无需删除文案</u><small> 但如果视频链接正确但解析失败请删掉文案后重试</small></p>
          </div>
          <hr>
          <div class="main" v-loading="loading">
            <div class="grid-content">
                <el-input placeholder="请输入短视频链接地址，需要去各短视频APP或平台复制链接" v-model="input" id="url" lass="input-with-select">
                  <el-button slot="prepend" @click="onClear">清空</el-button>
                  <el-button slot="append" @click="onSubmit">解析</el-button>
                </el-input>
                <!-- <el-button @click="onSubmit" >解析</el-button> -->
            </div>
            <div class="download" v-if="seenVideo">
              <h4>{{info.title}}</h4>
              <!-- <a :href="info.img" target="_blank"><el-button plain>下载封面</el-button></a>
              <a :href="info.url" target="_blank"><el-button plain>下载视频</el-button></a> -->

              <el-button plain @click="clickDownload(info.img)">下载封面</el-button>
              <el-button plain @click="clickDownload(info.url)">下载视频</el-button>
            </div>

            <br>

            <div class="download" v-if="seenVideo">
              <video :src="info.url" controls style="width:30%"></video>
            </div>

            <div v-if="seenPic">
              <el-button plain @click="clickDownloadAllImg(pics)">下载全部图片</el-button>
            </div>
            <br>
            <div class="waterfall" v-if="seenPic">
              <div class="item" v-for="url in pics" :key ="url">
                <el-image 
                  :src="url" 
                  :preview-src-list="pics">
                </el-image>
                <el-button plain @click="clickDownload(url)">下载图片</el-button>
                <br>
                <br>
                <br>
              </div>
            </div>
          </div>
      </el-card>
    </el-row>
</template>
<script>

import { downloadFiles } from "@/download/download.js";
import md5 from 'js-md5';

export default {
  data() {
    return {
      info: {},
      input: '',
      select: '',
      music: false,
      seenPic: false,
      seenVideo: false,
      loading: false,
      avatar: "images/app-watermark-miniprogram.jpg",
      pics: [],
    }
  },
  methods: {
    onSubmit() {
      const url = /http[s]?:\/\/[\w.]+[\w/]*[\w.]*\??[\w=&:\-+%]*[/]*/.exec(this.input)
      if (url == null || url.length == 0) {
        this.$notify.info({
              title: '温馨提示',
              message: '请输入短视频链接地址'
        });
        return
      }
      var toSign = ""
      var urlLen = url[0].length
      var count = 0
      if (urlLen > 5) {
        count = urlLen-5
        toSign = url[0].substring(0,count)
      }
      // console.log(toSign)
      // 两次md5
      var sign = md5(toSign); 
      // console.log(sign)
      sign = md5(sign); 
      // console.log(sign)
      this.axios.defaults.withCredentials = false;
      this.loading = true
        this.axios.get('https://v.postotime.net/video/parse?sign='+sign+'&url=' + url+'&c='+count)
        .then((res) => {
          if(res.data.url != null && res.data.url != ''){
            this.seenVideo = true
            this.seenPic = false
            res.data.img = res.data.img.replace('http://','https://')
            res.data.url = res.data.url.replace('http://','https://')
            this.info = res.data
            this.loading = false
            this.$notify.success({
              title: '解析成功',
              message: '请及时下载封面或视频'
            });
          } else if(res.data.pics != null) {
            this.seenPic = true
            this.seenVideo = false
            this.loading = false
            console.log(res.data)
            res.data.img = res.data.img.replace('http://','https://')
            var newPics = new Array()
            res.data.pics.forEach(element => {
              var e = element.replace('http://','https://')
              newPics.push(e)
            });
            res.data.pics = newPics
            this.info = res.data
            this.pics = res.data.pics
            console.log(res.data)
            this.$notify.success({
              title: '解析成功',
              message: '请及时下载图片'
            });
          } else{
            this.seenPic = false
            this.seenVideo = false
            this.loading = false
            this.$notify.error({
              title: '解析失败',
              message: '请检查链接地址'
            });
          }
        })
        .catch(function(err){ 
          console.log(err);
          this.seenPic = false
          this.seenVideo = false
          this.loading = false
          this.$notify.error({
              title: '解析失败',
              message: err.message
            });
        })
    },
    clickDownload (url) {
      url = url.replace('http://','https://')
      downloadFiles(url, md5(url));
    },
    clickDownloadAllImg(pics) {
      pics.forEach(element => {
        element = element.replace('http://','https://')
        downloadFiles(element, md5(element));
      });
    },
    onClear() {
      this.input = ''
    },
  }, 
}
</script>

<style>
  ::selection {
    background: rgba(0,149,255,.1);
  }

  body:before{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .3;
    z-index: -1;
    content: "";
    position: fixed;
    /* background-image: linear-gradient( 135deg, #CE9FFC 10%, #7367F0 100%); */
    background-image: linear-gradient( 135deg, #b6b0f0 10%, #b6b0f0 100%);
  }

  .grid-content {
    margin-top: 1em;
    border-radius: 4px;
    min-height: 50px;
  }

  .el-select .el-input {
    width: 80px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;

  }

  .box-card {
    margin-top: 0.5em!important;
    margin-bottom: 0.5em!important;
    opacity: .8;
  }

  @media screen and (max-width: 700px){
    .box-card {
      margin-top: 1em!important;
      margin-bottom: 1em!important;
    }
  }
  .download h3{
      margin-top: 2em;
    }

  .download button{
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  .waterfall {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1em;
    -webkit-column-gap: 1em;
    column-gap: 1em;
  }

  .item {
    padding: 5px;
    break-inside: avoid;
  }

  .item img {
    width: 100%;
    margin-bottom: 10px;
  }
  .typo {
    text-align: left;
  }
  .typo a {
    color: #2c3e50;
    text-decoration:none;
  }

  hr{
    height: 10px;
    margin-bottom: .8em;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.12);
  }
</style>
